import { buildApiUrl } from './apiConfig';

export const apiRequest1 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
     "Content-Type": "application/json",
      "Authorization": "rata378"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest2 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest3 = async (endpoint, token, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const apiRequest4 = async (endpoint, method = 'POST', data = null) => {
  const url = buildApiUrl(endpoint);

  const options = {
    method,
    headers: {
     "Content-Type": "application/json",
      "Authorization": "fvlnfkjvfosbfjbnfbfnblkj87742694296594298t5698249"
    },
    body: data ? JSON.stringify(data) : null
  };

  try {
    const response = await fetch(url, options);
    console.log(response);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

// src/components/Header.js
import React from 'react';
import '../../style/Header.css';
import logo2 from '../../assets/logo2.png';
import tlogo from '../../assets/tlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import addMoney from '../../assets/add_money.png';


const Header = ({ mode, userId, authorization }) => (
  <header className="header">
    <div className="logo">
        <img src={logo2} alt="Trade Insights Logo" className="logo-image" />
        <img src={tlogo} alt="Trade Insights" className="logo-image-title" />
    </div>
    <div className="actions">
      { mode === "Live" ?
      <button className="balance header-btn">
        <div className='balance-symbol'>
            <FontAwesomeIcon icon={faIndianRupeeSign} color='#4318FF' width={10} />
        </div>
        
        <div className='balance-text'>
            <span className='balance-text1'>Available Balance</span>
            <span className='balance-text2'>₹350.4</span>
        </div>
      </button>:null
      }
      { mode === "Live" ?
      <button className="add-money header-btn">
        <img alt="Add money" src={addMoney} />
        <span>Add money</span>
      </button>:null
      }
      <button className="kyc-verified header-btn">
        <span>{mode ==="Live"?'KYC Verified':'KYC Pending'}</span>
        <div></div>
      </button>
    </div>
  </header>
);

export default Header;

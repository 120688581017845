// src/components/AlgoCard.js
import React from 'react';
import '../../style/AlgoCard.css';
import algo1 from '../../assets/algo1.png';
import { useNavigate } from "react-router-dom";



const AlgoCard = ({ name, author, capital, status }) => {
    const formatNumber = (num) => {
        if (num >= 10000000) {
          // Convert to crores
          return (num / 10000000).toFixed(2) + "cr";
        } else if (num >= 100000) {
          // Convert to lacs
          return (num / 100000).toFixed(2) + "lac";
        } else if (num >= 1000) {
          // Convert to thousands
          return (num / 1000).toFixed(0) + "k";
        } else {
          // Return as is if less than 1000
          return num.toString();
        }
      };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/plans"); // Navigate to the "/about" page
  };
      
  return (
    <div className="algo-card">
        <div className="algo-image">
        <img src={algo1} alt={name} />
        </div>
        <div className="algo-details">
        <h4>{name}</h4>
        <p className='author'>By {author}</p>
        <div className='card-bottom'>
            <p className="current-bid">Min capital: ₹{formatNumber(capital)}</p>

            <button className={`place-bid ${
        status === "Live"
            ? "live2"
            : status === "Open"
            ? "open"
            : status === "Paused"
            ? "paused"
            : "subscribe"
        }`} onClick={status === "Live"
          ? null
          : status === "Open"
          ? null
          : status === "Paused"
          ? null
          : handleNavigate}>
            {status === "Live" ? "Pause" : status === "Open" ? "Review & Run" : status === "Paused" ? "Resume" : "Subscribe"}
            </button>
        </div>
        
        </div>
    </div>
  )
};

export default AlgoCard;

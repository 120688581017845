// src/components/InsightsCard.js
import React from 'react';
import '../../style/InsightsCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const InsightsCard = ({mode,userid,authorization}) => {

const currentAmount = mode === "Live"?'₹3,732,000':'₹0';
const gain = mode === "Live"?'₹+₹3,24,500':'+₹0';
const gainPercent = mode === "Live"?'45.6%':'0%';
const invested = mode === "Live"?'₹3,73,200':'₹0';


return (
  <div className="insights-card">
    <div className="card-content1">
      <h3>Current</h3>
      <p className="current-amount">{currentAmount}</p>
      <p className="gain">{gain} <span className='gain-icon'><FontAwesomeIcon icon={faCaretUp} /></span> <span className="gain-percentage">{gainPercent}</span></p>
      
    </div>
    <div className="card-content2">
        <h4>Invested</h4>
        <p className="invested-amount">{invested}</p>
    </div>
    {/* <div className="graph">
      <img src="path/to/graph.png" alt="Growth Graph" />
    </div> */}
  </div>
);
};

export default InsightsCard;

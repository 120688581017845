import React, { useEffect, useState } from 'react';
import '../style/PurchasePage.css';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import globeImage from '../assets/PurchasePage_Globe.png';
import { createTransaction, getPlan } from '../services/userService';


const PurchasePage = () => {
    const [plans, setPlans] = useState([]);
    const [token, setToken] = useState(null);
    const [activeOption, setActiveOption] = useState('billing-yearly'); // Default active option
    const [baseMonthlyPrice, setBaseMonthlyPrice] = useState(null);
    const [baseMarginRequired, setBaseMarginRequired] = useState(null);
    const [baseYearlyPrice, setBaseYearlyPrice] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null); 

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };



    useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
    const fetchPlans = async () => {
        if(token){
            const response = await getPlan(token, {});
            setPlans(response.data);
            const planWithSequenceOne = response.data.find(plan => plan.Sequence === 1);

            // Extract MonthlyPrice if the plan exists
            if (planWithSequenceOne) {
                setBaseMonthlyPrice(planWithSequenceOne.MonthlyPrice);
                setBaseYearlyPrice(planWithSequenceOne.YearlyPrice);
                setBaseMarginRequired(planWithSequenceOne.MarginRequired)
                setSelectedPlan(planWithSequenceOne);
            }
        }
    };
  
    fetchPlans();
  }, [token]); 
   

    

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const purchase = async () => {
        const profile_id = localStorage.getItem('profile_id');
        const amount = activeOption === "billing-yearly"?(Math.round(selectedPlan.YearlyPrice)):(Math.round(selectedPlan.MonthlyPrice));
        const response = await createTransaction({"profile_id":profile_id,"amount":amount,"status":false});
        console.log(response.data.data.instrumentResponse.redirectInfo.url);
        window.open(response.data.data.instrumentResponse.redirectInfo.url, "_blank"); 
    };

    return (
        <>
            <div className='plans-header'>
                <h1 className="plans-heading">Affordable, systematic trading Algos</h1>
                <p className="plans-subheading">Plans based on your Investment amount</p>
            </div>
            <div className="plans-container">
                <div className="plans-left">
                    <img src={globeImage} alt="Globe" className="globe-image" />
                    <div className="plans-features">
                        <ul>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />All Powerful Algos</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />New Algos every month for free</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Auto money management</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Free Algo maintenance</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Dedicated Support</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Fully automated systems & dashboard</li>
                            <li><IoMdCheckmarkCircleOutline className='small-screen-tick' />Zero broker account opening fee</li>
                        </ul>
                    </div>
                </div>
                <div className="plans-content">
                    <div className="pricing-container">
                        {selectedPlan && selectedPlan.Name ? (
                        <div className="billing-box">
                            <div className={activeOption==="billing-monthly"?"billing-option billing-yearly":"billing-option billing-monthly"} onClick={() => handleOptionClick('billing-monthly')}>
                                <div>Billed <span>Monthly</span></div>
                                <div className='price-div'>₹{(Math.round(selectedPlan.MonthlyPrice))}<span>/month</span></div>
                            </div>
                            <div className={activeOption==="billing-yearly"?"billing-option billing-yearly":"billing-option billing-monthly"} onClick={() => handleOptionClick('billing-yearly')}>
                                <div>Billed <span>Yearly</span> <span className="b-discount">75%+ OFF</span></div>
                                <div className='price-div'>₹{(Math.round(selectedPlan.YearlyPrice/12))}<span>/month</span></div>
                            </div>
                        </div>):null
                        }

                        <div className="features-and-plans-container">
                            <div className="features-list">
                                <h3>All Features</h3>
                                <div className="checkmarks-container">
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                    <IoMdCheckmarkCircleOutline />
                                </div>
                            </div>

                            {selectedPlan && selectedPlan.Name ? (

                            <div className="plans">
                                {plans.map((plan, index) => (
                                    <div
                                        key={index}
                                        className={`plan-option ${selectedPlan.Name === plan.Name ? 'selected' : ''}`}
                                        onClick={() => handlePlanSelect(plan)}
                                    >
                                        <span className="icon">
                                            <IoMdCheckmarkCircle />
                                        </span>
                                        <div className="details">
                                            <p>{plan.Name}</p>
                                            <p className='savings'>{activeOption === "billing-monthly"? Math.round(((baseMonthlyPrice*(plan.MarginRequired/baseMarginRequired) - plan.MonthlyPrice)/(baseMonthlyPrice*(plan.MarginRequired/baseMarginRequired)))*100):Math.round(((baseMonthlyPrice*12*(plan.MarginRequired/baseMarginRequired) - plan.YearlyPrice)/(baseMonthlyPrice*12*(plan.MarginRequired/baseMarginRequired)))*100)}% Savings</p>

                                        </div>
                                        <div className={plan.price === "Contact us"?"price price2":"price"}>₹{activeOption === "billing-yearly"?(Math.round(plan.YearlyPrice/(30*12))):(Math.round(plan.MonthlyPrice/(30)))}<span>/day</span></div>
                                    </div>
                                ))}
                                <div
                                        key={100}
                                        className={`plan-option ${selectedPlan.Name === "Custom (₹10lac +)" ? 'selected' : ''}`}
                                        onClick={() => handlePlanSelect({
                                            Name: 'Custom (₹10lac +)',
                                            
                                        })}
                                    >
                                        <span className="icon">
                                            <IoMdCheckmarkCircle />
                                        </span>
                                        <div className="details">
                                            <p>Custom (₹10lac +)</p>
                                            <p className="savings">-% Savings</p>
                                        </div>
                                        <div className="price price2">{activeOption === "billing-yearly"?"Contact us":"Contact us"}<span>/day</span></div>
                                    </div>
                            </div>):null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {selectedPlan && selectedPlan.Name ? (

            <div className="bottom-section">
                <div className="investment-details">
                    <p>{selectedPlan.Name}</p>
                    <span className='savings'>{activeOption === "billing-monthly"? Math.round(((baseMonthlyPrice*(selectedPlan.MarginRequired/baseMarginRequired) - selectedPlan.MonthlyPrice)/(baseMonthlyPrice*(selectedPlan.MarginRequired/baseMarginRequired)))*100):Math.round(((baseMonthlyPrice*12*(selectedPlan.MarginRequired/baseMarginRequired) - selectedPlan.YearlyPrice)/(baseMonthlyPrice*12*(selectedPlan.MarginRequired/baseMarginRequired)))*100)}% Savings</span>
                </div>
                <div className="price-and-button">
                    <h3>₹{activeOption === "billing-yearly"?(Math.round(selectedPlan.YearlyPrice)):(Math.round(selectedPlan.MonthlyPrice))}<span>{activeOption === "billing-yearly"?"/year":"/month"}</span></h3>
                    <button className="subscribe-button" onClick={purchase}>Subscribe</button>
                </div>
            </div>):null
            }
        </>
    );
};

export default PurchasePage;

// src/components/AlgosList.js
import React, { useEffect, useState } from 'react';

import AlgoCard from './AlgoCard';
import '../../style/AlgosList.css';
import { getAlgos } from '../../services/userService';



const AlgosList = ({mode,userId,authorization,token}) => {
  const [algos, setAlgos] = useState([]);
  

  

  useEffect(() => {
    const fetchAlgos = async () => {
        if(token){
            const response = await getAlgos(token, {});
            setAlgos(response.data);
        }
    };
  
    fetchAlgos();
  }, [token]); 
//   const algos = [
//     { title: "Abstract Colors", author: "Esther Jackson", capital: "₹50k", status:"Live" },
//     { title: "ETH AI Brain", author: "Nick Wilson", capital: "₹1lac", status:"Open" },
//     { title: "Colorful Heaven", author: "Mark Benjamin", capital: "₹50k" , status: "Paused"},
//     { title: "3D Cubes Art", author: "Manny Gates", capital: "₹50k" , status:"Open"},
//   ];
  return (
    <div className="algos-list">
        <div className='algos-header'>
            <h3>Algos</h3>
            <div className="algo-tabs">
                <button className="tab-all">All</button>
                <button className='tab-live'>Live</button>
            </div>
        </div>
        
        
        <div className="algo-cards">
        {algos.map((algo, index) => (
            <AlgoCard key={index} name={algo.Name} author={algo.Author} capital={algo.MarginRequired} status={mode==="New"?"Subscribe":"Subscribe"} />
            
        ))}
        
        </div>
    </div>
  );
};

export default AlgosList;

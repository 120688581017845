import { apiRequest1, apiRequest2, apiRequest3, apiRequest4 } from '../api/apiService';

export const sendOtp = (data) => apiRequest1('users/sendOtp', 'POST', data);
export const verifyOtp = (data) => apiRequest1('users/verifyOtp', 'POST', data);
export const getUserById = (token, data) => apiRequest3('users/getUserById', token, 'POST', data);
export const updateUser = (token, data) => apiRequest3('users/update', token, 'POST', data);
export const getAccess = (data) => apiRequest1('users/getAccess', 'POST', data);
export const kycPostback = (data) => apiRequest2('users/kycPostback', 'POST', data);
export const registerId = (data) => apiRequest4('users/registerId', 'POST', data);
export const createAlgo = (token, data) => apiRequest3('algos/create', token, 'POST', data);
export const updateAlgo = (token, data) => apiRequest3('algos/update', token, 'POST', data);
export const getAlgos = (token, data) => apiRequest3('algos/getAlgos', token, 'POST', data);
export const getSubscribedAlgos = (token, data) => apiRequest3('algos/getSubscribedAlgos', token, 'POST', data);
export const getUnsubscribedAlgos = (token, data) => apiRequest3('algos/getUnsubscribedAlgos', token, 'POST', data);
export const createPlan = (token, data) => apiRequest3('plans/create', token, 'POST', data);
export const updatePlan = (token, data) => apiRequest3('plans/update', token, 'POST', data);
export const getPlan = (token, data) => apiRequest3('plans/getPlans', token, 'POST', data);
export const getPlanByProfileID = (token, data) => apiRequest3('plans/getPlanByProfileID', token, 'POST', data);
export const createSubscription = (token, data) => apiRequest3('subscriptions/create', token, 'POST', data);
export const updateSubscription = (token, data) => apiRequest3('subscriptions/update', token, 'POST', data);
export const createTransaction = (data) => apiRequest2('transactions/create', 'POST', data);
export const tranCallback = (data) => apiRequest2('transactions/tranCallback', 'POST', data);
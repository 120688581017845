import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import InsightsCard from './dashboard/InsightsCard';
import TradeList from './dashboard/TradeList';
import AlgosList from './dashboard/AlgosList';
import './../style/DashboardPage.css';
import { getPlanByProfileID } from '../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";



const DashboardPage = () => {
  const [kycStatus, setkycStatus] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [authorization, setAuthorization] = useState(null);
  const [authExpiry, setAuthExpiry] = useState(null);
  const [loginTime, setLoginTime] = useState(null);
  const [mode, setMode] = useState(null);
  const [plan, setPlan] = useState(null);

  // Fetch data from localStorage
  const fetchLocalStorageData = async () => {
    const kycStatus = localStorage.getItem('kyc_status');
    const profileId = localStorage.getItem('profile_id');
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const authorization = localStorage.getItem('authorization');
    const authExpiry = localStorage.getItem('auth_expiry');
    const loginTime = localStorage.getItem('login_time');

    setkycStatus(kycStatus);
    setProfileId(profileId);
    setUserId(userId);
    setToken(token);
    setAuthorization(authorization);
    setAuthExpiry(authExpiry);
    setLoginTime(loginTime);

    const response = await getPlanByProfileID(token,{ 'profile_id': profileId });
    setPlan(response.data);

    if(kycStatus === "false" && response.data ==  null){
      setMode("New")
      console.log("a")
    }else if(kycStatus === "false" && response.data != null){
      setMode("KYC")
      console.log("b")
    }else{
      setMode("Live")
      console.log("c")
    }
    
    

    
  }

  // Fetch localStorage data on component mount
  useEffect(() => {
    fetchLocalStorageData();
  }, [token]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/plans"); // Navigate to the "/about" page
  };
  
  return (
    <div className="dashboard">
      <Header mode={mode} userId={userId} authorization={authorization} />
      {mode === "New" && (
      <div className='top-notify'>
        <FontAwesomeIcon className='notify-icon' icon={faCircleInfo}></FontAwesomeIcon>
        <span className='notify-text'>Unlock the algos by subscribing at just ₹33/day</span>
        <button onClick={handleNavigate}>Subscribe</button>
      </div>
      )}
      <div className="main-content">
        <InsightsCard mode={mode} userId={userId} authorization={authorization} />
        <TradeList mode={mode} userId={userId} authorization={authorization} />
      </div>
      <AlgosList mode={mode} userId={userId} authorization={authorization} token={token} />
    </div>
  );
};

export default DashboardPage;

// src/components/TradeList.js
import React from 'react';
import '../../style/TradeList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const trades = [
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },
  { name: "Nifty 14th Sep 23500 call", invested: "₹21053.00", profit: "+2542", percentage: "2.45%" },

];

const TradeList = ({mode,userId,authorization}) => {
  return (
    <div className="trade-list">
        <h3>Trades</h3>
        {mode === "Live" ? 
        (<div>
            <div className="tabs">
            <button className="live">Live</button>
            <button className='exited'>Exited</button>
            </div>
            <ul>
            {trades.map((trade, index) => (
                <li key={index}>
                    <div className='trade-flex'>
                        <div className="trade-details">
                            <p className="trade-name">{trade.name}</p>
                            <p className="trade-invested">Invested {trade.invested}</p>
                        </div>
                        <div className="trade-profit">
                            <span className="profit-amount">{trade.profit}</span>
                            <span className="profit-percentage"><FontAwesomeIcon className='trade-caret' icon={faCaretUp} />{trade.percentage}</span>
                        </div>
                    </div>
                
                </li>
            ))}
            </ul>
        </div>):(<div className='trade-holder'>Your live trades will show here</div>)
        }
    </div>
  );
};

export default TradeList;
